import React from "react"
import { ButtonStyled } from "./style"

export const CustomButton = ({
  children,
  isDarkGreen,
  isGreen,
  isYellow,
  isDarkBlue,
  padding,
  margin,
  fsize,
  bradius,
  className="",
  style,
  isBgGreen,
  type=""
}) => {
  return (
    <>
      {isDarkGreen && (
        <ButtonStyled
          padding={padding}
          margin={margin}
          fsize={fsize}
          bradius={bradius}
          className={className}
          style={style}
          type=""
        >
          {children}
        </ButtonStyled>
      )}
      {isGreen && (
        <ButtonStyled
          color="#FFD27C"
          bcolor="#48A9B5"
          colorres="#48A9B5"
          bcolorres="#FFD27C"
          margin={margin}
          fsize={fsize}
          bradius={bradius}
          padding={padding}
          className={className}
          style={style}
          type=""
        >
          {children}
        </ButtonStyled>
      )}
      {isYellow && (
        <ButtonStyled
          color={isBgGreen ? "#48A9B5" : "#166f7b"}
          bcolor="#FFD27C"
          colorres="#FFD27C"
          bcolorres={isBgGreen ? "#48A9B5" : "#166f7b"}
          margin={margin}
          fsize={fsize}
          bradius={bradius}
          padding={padding}
          className={className}
          style={style}
          type=""
        >
          {children}
        </ButtonStyled>
      )}
      {isDarkBlue && (
        <ButtonStyled
          color="#FFD27C"
          bcolor="#196e97"
          colorres="#196e97"
          bcolorres="#FFD27C"
          margin={margin}
          fsize={fsize}
          bradius={bradius}
          padding={padding}
          className={className}
          style={style}
          type=""
        >
          {children}
        </ButtonStyled>
      )}
    </>
  )
}
