import styled from "styled-components"
import Container from "react-bootstrap/Container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import { Link } from "gatsby"
import MissionNewBig from "../../images/mission-bg.jpg"
import MissionNewBigRes from "../../images/mission-bg-res.jpg"

export const StyledSection = styled.section`
  background-image: url(${MissionNewBig});
  background-repeat: no-repeat;
  background-size: cover, contain;
  min-height: 800px;
  @media (max-width: 576px) {
    background-image: url(${MissionNewBigRes});
    min-height: 850px !important;
    /* background-size: 100% auto, 100% 200px, cover; */
    /* background-position: bottom -1px left, bottom center, bottom center; */
    background-position: bottom -100px;
  }
`

export const MissionContainer = styled.div`
  /* min-height: 450px; */
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  @media (max-width: 576px) {
    min-height: 850px;
  }
`

export const TitleContainer = styled(Container)`
  padding-top: 3rem;
  @media (max-width: 576px) {
    padding-top: 0;
  }
`

export const MissionTittle = styled.h4`
  color: #166f7b;
  font-family: "GothamBold";
  font-size: 36px;
  /* max-width: 634px; */
  @media (max-width: 576px) {
    text-align: center;
    font-size: 25px;
  }
`

export const MainColText = styled(Col)`
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const MissionDesc = styled.p`
  font-family: "GothamBook";
  font-size: 14px;
  margin-bottom: 1.5rem;
  width: 80%;
  @media (max-width: 576px) {
    text-align: center;
    font-size: 13px;
    width: 100%;
  }
`

export const StyledOrnament = styled.img`
  max-width: 380px;
  margin-top: auto;
  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledButton = styled(Button)`
  font-family: "GothamMedium";
  background-color: #ffd27c;
  color: #166f7b;
  font-weight: medium;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  transition: 300ms;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  :hover,
  :focus,
  :active {
    outline: 0;
    /* box-shadow: none !important; */
    transition: 200ms;
    background-color: #166f7b !important;
    color: #ffd27c !important;
    -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
    box-shadow: -1px 7px 5px -4px #a0a0a0;
  }
`

export const MissionDownloadTittle = styled.h4`
  color: white;
  font-family: ${p => p.ffamily || "GothamBold"};
  font-size: ${p => p.fsize || "22px"};
  margin-top: ${p => p.mt};
  margin-bottom: 0;
  @media (max-width: 576px) {
    text-align: center;
  }
`

export const DownloadImg = styled.img`
  width: 140px;
  height: 39px;
  margin-bottom: 5px;
`

export const CustomLink = styled(Link)`
  color: white;
  font-weight: 600;
  text-decoration: underline;
  :hover {
    color: white;
  }
`

export const DownloadContainer = styled.div`
  background-color: #166f7b;
  border-radius: 20px;
  width: 378px;
  height: 124px;
  padding: 20px 30px;
  position: relative;
  @media (max-width: 576px) {
    width: 95%;
  }
`

export const RowDownload = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
`

export const DownloadCtaCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: nowrap;
  /* @media (max-width: 768px) {
    flex-direction: row;
  } */
`

export const DownloadTextCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
  flex-wrap: nowrap;
  @media (max-width: 576px) {
    padding-left: 15px;
  }
`

export const CircleYellow = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffd27c;
  position: absolute;
  left: -25px;
  top: 35px;
`

export const MissionAskQuestionDiv = styled.div`
  background-color: white;
  width: 150%;
  padding: 10px 20px;
  margin-top: 200px;
  @media (max-width: 576px) {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
`

export const MissionAskQuestionText = styled.p`
  font-family: "GothamBook";
  font-size: 18px;
  color: #166f7b;
  margin-bottom: 0;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`

export const MissionAskQuestionLink = styled(Link)`
  font-family: "GothamBook";
  font-size: 18px;
  color: #166f7b;
  font-weight: bold;
  :hover {
    color: #166f7b;
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
`
